import "../styles/Header.css";

import HeaderLi from "./HeaderLi";

export default function Header() {
  return (
    <header className="header">
      <span></span>
      <nav>
        <ul>
          <HeaderLi icon={"github"} />
          <HeaderLi icon={"linkedin"} />
          <HeaderLi icon={"medium"} />
        </ul>
      </nav>
    </header>
  );
}
