import "../styles/Header.css";

import githubLogo from "../../src/github.svg";
import linkedinLogo from "../../src/linkedin.svg";
import mediumLogo from "../../src/medium.svg";

const icons = {
  github: {
    icon: githubLogo,
    url: "https://github.com/pedro-rivas/",
  },
  linkedin: {
    icon: linkedinLogo,
    url: "https://www.linkedin.com/in/pedrorrivas/",
  },
  medium: {
    icon: mediumLogo,
    url: "https://medium.com/@pedrocfyd/",
  },
};

const iconSize = { width: 28, height: 28 };

export default function HeaderLi(props) {
  return (
    <li>
      <a href={icons[props.icon].url} target={"_blank"}>
        <img style={iconSize} src={icons[props.icon].icon} alt={props.icon} />
      </a>
    </li>
  );
}
