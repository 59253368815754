import React, { Suspense, useState } from "react";

import "./styles/App.css";

import Spline from "@splinetool/react-spline";
import Header from "./components/Header";

const height = window.innerHeight;
const scene = "https://prod.spline.design/4fwkLbc8OPXXJcWS/scene.splinecode";

export default function App() {

  const [loading, setLoading] = useState(true);

  function onLoad(){
   setLoading(false)
  }
  return (
    <div>
      <Header />
      <div style={{ height }} className="splineGrap">
        { loading ? <div style={{fontSize:20}}>Loading...</div> : null}
        <Suspense fallback={null}>
          <Spline {...{ scene, onLoad, onWaiting: () => console.log('wating') }} />
        </Suspense>
      </div>
    </div>
  );
}
